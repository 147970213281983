<template>
    <div>
        <v-btn v-bind:id="idQuizButton" :aria-label="item.data.cardAction.title.trim()"
            v-bind:data-title="item.data.cardAction.value.trim()"
            class="btn btn-chat-bot btn-chat-bot-act options-register-user qualtrics" v-bind:class="[claseGeneral]"
            :data-ref="refId" @click="obtainQualtrixData">
            {{ item.data.cardAction.title.trim() }}
        </v-btn>
        <div v-if="modalQuatrix">
            <input type="hidden" id="amx_region" name="Region" :value="dataQ.Region">
            <input type="hidden" id="amx_canal" name="Canal" :value="dataQ.Channel">
            <input type="hidden" id="amx_servicio" name="Servicio" :value="dataQ.Service">
            <input type="hidden" id="amx_fecha_hora_inicio" name="FechaHoraInicio" :value="dataQ.DateTimeInit">
            <input type="hidden" id="amx_fecha_hora_fin" name="FechaHoraFin" :value="dataQ.DateTimeEnd">
            <input type="hidden" id="amx_numero_linea" name="NumeroLinea" :value="dataQ.LineNumber">
            <input type="hidden" id="amx_email" name="Email" :value="dataQ.Email">
            <input type="hidden" id="amx_id_conversacion" name="IdConversacion" :value="dataQ.IdConversation">
            <input type="hidden" id="amx_tipo_cliente" name="TipoCliente" :value="dataQ.ClientType">
            <input type="hidden" id="amx_motivo_visita" name="MotivoVisita" :value="dataQ.ReasonVisit">
            <input type="hidden" id="amx_autenticado" name="Autenticado" :value="dataQ.IsAuth">
        </div>
    </div>
</template>

<script>
import Vue from "vue"
import QualtrixData from "@/models/qualtrixData";

export default Vue.component("EncuestaQualtrix", {
    props: [
        "item",
        "claseGeneral",
        "refId"
    ],
    created() {
        (function () {
            var g = function (e, h, f, g) {
                this.get = function (a) { for (var a = a + "=", c = document.cookie.split(";"), b = 0, e = c.length; b < e; b++) { for (var d = c[b]; " " == d.charAt(0);)d = d.substring(1, d.length); if (0 == d.indexOf(a)) return d.substring(a.length, d.length) } return null };
                this.set = function (a, c) { var b = "", b = new Date; b.setTime(b.getTime() + 6048E5); b = "; expires=" + b.toGMTString(); document.cookie = a + "=" + c + b + "; path=/; " };
                this.check = function () { var a = this.get(f); if (a) a = a.split(":"); else if (100 != e) "v" == h && (e = Math.random() >= e / 100 ? 0 : 100), a = [h, e, 0], this.set(f, a.join(":")); else return !0; var c = a[1]; if (100 == c) return !0; switch (a[0]) { case "v": return !1; case "r": return c = a[2] % Math.floor(100 / c), a[2]++, this.set(f, a.join(":")), !c }return !0 };
                this.go = function () { if (this.check()) { var a = document.createElement("script"); a.type = "text/javascript"; a.src = g; document.body && document.body.appendChild(a) } };
                this.start = function () { var t = this; "complete" !== document.readyState ? window.addEventListener ? window.addEventListener("load", function () { t.go() }, !1) : window.attachEvent && window.attachEvent("onload", function () { t.go() }) : t.go() };
            };
            try { (new g(100, "r", "QSI_S_ZN_5z3aNzoz0OGiYAK", "https://zn5z3anzoz0ogiyak-telcelmexico.siteintercept.qualtrics.com/SIE/?Q_ZID=ZN_5z3aNzoz0OGiYAK")).start() } catch (i) { }
        })();
    },
    data() {
        return {
            modalQuatrix: false,
            idQuizButton: "btnShowQuiz",
            dataQ: new QualtrixData(),
            visibleSurvey: false
        }
    },
    methods: {
        obtainQualtrixData() {

            if(this.existButton()){
                this.showFakeSurvey();
            }else{
                this.modalQuatrix = true;
                this.dataQ.Region = this.item.region;
                this.dataQ.Channel = this.item.channel;
                this.dataQ.Service = 'TelcelBot';
                this.dataQ.DateTimeInit = this.item.dateInit;
                this.dataQ.DateTimeEnd = this.formatAMPM(new Date());
                this.dataQ.IdConversation = this.item.guidKeyChat,
                this.dataQ.ReasonVisit = this.item.motivoVisita;
                this.dataQ.LineNumber = this.item.lineNumber;
                this.dataQ.Email = this.item.email;
                this.dataQ.ClientType = this.item.clientType;
                this.dataQ.IsAuth = this.item.isAuth;

                this.checkModals();
            }
        },
        checkModals() {
            const target = document.getElementsByClassName("QSIWebResponsive");

            if (!target[1]) {
                window.setTimeout(this.checkModals, 500);
                return;
            }

            target[1].setAttribute("style", "display:none");
            this.createButtton();

            this.$emit('OpenModal', true);
        },
        createButtton(){
            const button = document.getElementsByClassName("QSIWebResponsiveDialog-Layout1-SI_8le8OXGjNkiaoMm_close-btn");
            const divElement = document.getElementsByClassName("QSIWebResponsiveDialog-Layout1-SI_8le8OXGjNkiaoMm_close-btn-container");
            button[0].setAttribute("style", "display:none");

            const buttton = document.getElementById("NewButtonSurvey");
            console.log("Button nuevo: " + button);

            if(button.length > 1) return;
            
            let newButton = document.createElement("button");
            newButton.id = "NewButtonSurvey";
            newButton.className = "QSIWebResponsiveDialog-Layout1-SI_8le8OXGjNkiaoMm_close-btn";
            newButton.tabIndex = 0;
            newButton.ariaLabel = "Cerrar";
            newButton.innerHTML = '<img class="" style="height: 16px; width: 16px; margin-top: 1px;" src="https://siteintercept.qualtrics.com/WRSiteInterceptEngine/../WRQualtricsShared/Graphics/siteintercept/wr-dialog-close-btn-black.png" alt="Cerrar">';
            newButton.addEventListener("click", function() {
                let target = document.getElementsByClassName("QSIWebResponsive");
                target[0].setAttribute("style", "display:none");
                this.visibleSurvey = true;
                this.idQuizButton = "btnShowQuizChanged"
            });

            this.visibleSurvey = true;
            divElement[0].appendChild(newButton);
            this.$emit('OpenModal', false);
        },
        existButton(){
            const surveyButton = document.getElementById('NewButtonSurvey');
            if(surveyButton) return true;
            return false;
        },
        showFakeSurvey(){
            let target = document.getElementsByClassName("QSIWebResponsive");
            target[0].setAttribute("style", "display:block");
        },
        formatAMPM(date) {
            let hours = date.getHours();
            let minutes = date.getMinutes();
            let ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12;
            let strTime = hours + ':' + (minutes < 10 ? '0' + minutes : minutes) + ' ' + ampm;
            return strTime;
        }
    }
})
</script>
<style>

</style>